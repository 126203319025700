.mainPage {
  background-color: rgb(240, 240, 240);
  min-height: 100vh;
}
.flexContainer {
  display: flex;
  justify-content: center;
}

p {
  font-size: 40px;
  line-height: 60px;
  text-align: left;
}

.name {
  color: rgb(255, 115, 0);
  font-weight: 700;
}

.logo {
  width: 75px;
  padding-right: 10px;
}